<template>
    <div class="display-card userDetails">
        <div class="userImage">
            <img :src="user.avatar_path" class="img-fluid" :alt="user.name">
        </div>
        <h2> {{ user.name }} </h2>
        <div class="shortbio text-center"> <p v-html="user.biography"></p> </div>
        <div class="bg-white p-2 mb-3">
            <ul>
                <li>
                    <span>Email:</span>
                    <span> {{user.email}} </span>
                </li>
                <li>
                    <span>Password:</span>
                    <span>*********</span>
                </li>
                <li>
                    <span>Phone:</span>
                    <span> {{ user.mobile_number }} </span>
                </li>
            </ul>
        </div>
        <button class="univ-btn w-100 text-center logOut" @click="logoutAction">
            <span class="icon-arrow"></span> Log Out
        </button>
    </div>
</template>
<script>
import Helper from "@/services/helper"
import axios from "axios"
import store from "@/store"
import api from '@/services/api'
export default {
    name: "ProfileCard",
    data() {
        return {
            user:{
                name: store.getters.user.name,
                email: store.getters.user.email,
                biography: store.getters.user.biography,
                mobile_number: store.getters.user.mobile_number,
                address: store.getters.user.address,
                avatar_path: store.getters.user.avatar_path
            },
        };
    },
    mounted() {
        let app = this;
        app.getUser(store.getters.user.id);
        app.$root.$on("profile-update", ($event) => {
            app.user.name = $event.name;
            app.user.email = $event.email;
            app.user.biography = $event.biography;
            app.user.mobile_number = $event.mobile_number;
            app.user.address = $event.address;
            app.user.avatar_path = $event.avatar_path;
        });
    },
    methods:{
        getUser(user_id){
            let app = this;
            axios.get(api.getUrl('/user/'+user_id)).then(function (response) {
                if(response.data.success == true){
                    app.user.email = response.data.data.email;
                    app.user.mobile_number   = response.data.data.mobile_number;
                    app.user.avatar_path     = response.data.data.avatar_path;
                }
            });
        },
        logoutAction() {
            let app = this;
            Helper.logout();
            app.isAuthenticated = false;
            app.$router.push({ path: '/' });
        },
    },
}
</script>