<template>
    <div class="row profleInfo">
        <div class="col-12">
            <h3>Expired Courses</h3>
            <span class="section-border"></span>
        </div>
        <template v-if="data.length > 0">
            <div class="col-xl-4 my-3" v-for="(item,i) in data" :key="i">
                <CourseItem :item="item.item" :source="itemSource" :learningStatus="item.learning_status" :expStatus="item.expiration_status" :expDate="item.expiration_date"/>
            </div>
        </template>
        <template v-else>
            <div class="noData">
                <h4>No courses expired at the moment.</h4>
            </div>
        </template>
    </div>
</template>
<script>
import CourseItem from '@/components/CourseItem'
export default{
    name: 'ExpiredCourses',
    components: {
        CourseItem
    },
    props:{
        courseItems:{
            required:true,
            type:[Array,Object],
        }
    },
    data() {
        return {
            itemSource:'private',
            data: this.courseItems,
            pageData: true,
        }
    },
    watch: {
        courseItems(value) {
            let app = this;
            app.data = value;
        },
    },
}
</script>