<template>
    <div class="profleInfo">
        <h3>My Information</h3>
        <span class="section-border"></span>
        <div class="form-group row">
            <div class="col-xl-4 mb-3">
                <label>First Name <span class="required-field">*</span></label>
                <input type="text" class="univ-input" placeholder="First Name" v-model="userProfile.name" >
                <span class="error-field" v-if="updateErrors.name"><span class="icon-warning"></span> {{ updateErrors.name }}</span>
            </div>
            <div class="col-xl-4 mb-3">
                <label>Address <span class="required-field">*</span></label>
                <input type="text" v-model="userProfile.address" class="univ-input">
                <span class="error-field" v-if="updateErrors.address"><span class="icon-warning"></span> {{ updateErrors.address }}</span>
            </div>
            <div class="col-xl-4 mb-3">
                <label>Phone Number <span class="required-field">*</span></label>
                <input type="number" class="univ-input" placeholder="Phone Number" v-model="userProfile.mobile_number" >
                <span class="error-field" v-if="updateErrors.mobile_number"><span class="icon-warning"></span> {{ updateErrors.mobile_number }}</span>
            </div>
        </div>
        <div class="form-group mb-3">
            <label>Biography</label>
            <textarea class="univ-input" rows="4" placeholder="Enter biography message here..." v-model="userProfile.biography" ></textarea>
        </div>
        <div class="form-group d-flex align-items-center mb-3">
            <div class="userImage" v-if="image" style="text-align: left;" >
                <img class="img-fluid" :src="generateURL(image)" />
            </div>
            <div class="userImage" style="text-align: left;" v-else>
                <img class="img-fluid" :src="user.avatar_path" />
            </div>
            <div class="file-upload ms-4">
                <label for="upload" class="file-upload__label">Upload Photo</label>
                <input id="upload" class="file-upload__input" type="file" name="file-input" @change="onImageChange" ref="file" accept=".pdf,.jpg,.jpeg,.png"  >
            </div>
        </div> 
        <hr/>
        <div class="form-group d-flex align-items-center mb-3">
            <label class="switch mb-0 me-2">
                <input type="checkbox" v-model="change_pw">
                <span class="slider round"></span>
            </label>
            <h3>Change Password</h3>
        </div>
        <span class="section-border"></span>
        <div class="form-group row" v-if="change_pw">
            <div class="col-xl-6 mb-3">
                <label>Current Password</label>
                <input type="password" class="univ-input" placeholder="Current Password" v-model="userProfile.old_password" >
            </div>
            <div class="col-xl-6 mb-3">
                <label>New Password</label>
                <input type="password" class="univ-input" placeholder="New Password" v-model="userProfile.new_password" >
            </div>
        </div>
        <div class="form-group row mt-4 justify-content-center">
            <div class="col-xl-3">
                <button class="univ-btn w-100" @click="formSubmit" > <b-spinner v-if="loginLoader" small></b-spinner>  Update</button>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import store from "@/store"
export default {
    name: 'ProfileInformation',
    data() {
        return {
            user:{
                name: store.getters.user.name,
                email: store.getters.user.email,
                biography: store.getters.user.biography,
                mobile_number: store.getters.user.mobile_number,
                address: store.getters.user.address,
                avatar_path: store.getters.user.avatar_path
            },
            dataStatus: true,
            userId:store.getters.user.id,
            userProfile:{
                id: store.getters.user.id,
                name: store.getters.user.name,
                biography: store.getters.user.biography,
                email: store.getters.user.email,
                mobile_number: store.getters.user.mobile_number,
                address: store.getters.user.address,

                avatar: store.getters.user.avatar,
                avatar_path: store.getters.user.avatar_path,

                new_avatar:"",
                old_password:"",
                new_password:"",
                pw_option:'disabled',
            },
            change_pw:false,
            image:"",
            loginLoader: false,
            errorMessage:"",
            updateErrors: {
                name:"",
                mobile_number:"",
                address: "",
            },
            editProfile:false,
        };
    },
    mounted() {
        let app = this;
        app.$root.$on("profile-update", ($event) => {
            app.user.name = $event.name;
            app.user.email = $event.email;
            app.user.mobile_number = $event.mobile_number;
            app.user.address = $event.address;
            app.user.avatar_path = $event.avatar_path;
        });
    },
    methods:{
        onImageChange(e){
            let app = this;
            app.image = e.target.files[0];
        },
        formSubmit(e) {
            e.preventDefault();
            let app = this;
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            }
            app.loginLoader = true;
            app.userProfile.new_avatar = app.image;
            if(app.change_pw){
                app.userProfile.pw_option = "enabled";
            }else{
                app.userProfile.pw_option = "disabled";
            }
            axios.post(api.getUrl('/user/update'), app.userProfile, config).then(function (response) {
                if(response.data.success == true){
                    app.loginLoader = false;
                    store.commit("setUserData",response.data.data);
                    app.$root.$emit('profile-update',response.data.data);
                    app.$toast.success(response.data.message,{position: "top-center",timeout: 1500});
                }else{
                    app.loginLoader = false;
                    if(response.data.message){
                        app.loginLoader = false;
                        app.errorMessage = response.data.message;
                        app.$toast.error(response.data.message,{position: "top-center",timeout: 1500});
                    }else if(response.data.errors.length > 0){
                        app.loginLoader = false;
                        response.data.errors.forEach(function(item) {
                            if(item.key == "name"){
                                app.updateErrors.name = item.message;
                            }else if(item.key == "mobile_number"){
                                app.updateErrors.mobile_number = item.message;
                            } else if(item.key == "address"){
                                app.updateErrors.address = item.message;
                            }
                        }); 
                    }
                }
            });
        },
        generateURL(file) {
            let fileSrc = URL.createObjectURL(file);
            setTimeout(() => {
                URL.revokeObjectURL(fileSrc);
            }, 1000);
            return fileSrc;
        },
        editProfileAction($val){
            let app = this;
            app.editProfile = $val;
        },
    },
    watch: {
        $route() {
            if (store.getters.authenticated) {
                this.isAuthenticated = true;
                this.user = store.getters.user;
                this.avatar_path = store.getters.user.avatar_path;
            } else {
                this.isAuthenticated = false;
            }
        }
    }
}
</script>