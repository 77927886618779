<template>
    <div class="row">
        <div class="col-xl-4">
            <div class="display-card userCounter">
                <h2>{{ enrolledCount }}</h2>
                <h3>Enrolled Courses</h3>
            </div>
        </div>
        <div class="col-xl-4">
            <div class="display-card userCounter">
                <h2>{{ expiredCount }}</h2>
                <h3>Expired Courses</h3>
            </div>
        </div>
        <div class="col-xl-4">
            <div class="display-card userCounter">
                <h2>{{ completedCount }}</h2>
                <h3>Completed Courses</h3>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    name: 'UserCounter',
    data() {
        return {
            enrolledCount:0,
            expiredCount:0,
            completedCount:0,
        }
    },
    mounted() {
        let app = this;
        app.$root.$on("enrolled-count", ($event) => {
            app.enrolledCount = $event;
        });
        app.$root.$on("expired-count", ($event) => {
            app.expiredCount = $event;
        });
        app.$root.$on("completed-count", ($event) => {
            app.completedCount = $event;
        });
    },
}
</script>