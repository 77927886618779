<template>
    <div class="profleInfo paymentHistory">
        <h3>Asset Download</h3>
        <span class="section-border"></span>
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th> Category </th>
                        <th> Action</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="data.length > 0"> 
                        <tr v-for="(item, i) in data" :key="i"> 
                            <td>{{ item.item.title }} </td>
                            <td>{{ item.item.category }} </td>
                            <td>
                                <div class="table-actions">
                                    <ul>
                                        <li>
                                            <a class="download-icon" v-b-tooltip.hover title="Download" @click="downloadFile(item)" target><span class="icon-arrow"></span></a>
                                        </li>
                                    </ul>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template v-else > 
                        <tr><td colspan="3">Not found.</td></tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import Helper from "@/services/helper"
import store from "@/store"
export default {
    name: 'AssetsDownload',
    data() {
        return {
            data: [],
            filterData:{
                user_id:store.getters.user.id,
                item_type:"asset",
                sort:"latest",
            },
        };
    },
    mounted(){
        let app = this;
        app.renderItems();
    },
    methods:{
        renderItems(){
            let app = this;
            Helper.showSpinner();
            axios.post(api.getUrl('/user/assets'), app.filterData).then(function (response) {
                if(response.data.success){
                    app.pageData = false;
                    app.data = response.data.data;
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
        downloadFile(item) {
            let fileName = item.item.slug;
            let params = {
                user_id:store.getters.user.id,
                order_id:item.order_id,
                order_item_id:item.order_item_id,
            };
            axios.post(api.getUrl('/download'),params, {responseType:'blob'}).then(function (res) {
                let blob = new Blob([res.data], { type: res.headers['content-type'] });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName.slice(fileName.lastIndexOf('/')+1);
                link.click()
            });
        }
    },
}
</script>