<template>
    <div class="row mt-4">
        <div class="col-12">
            <b-tabs content-class="">
                <b-tab title="Enrolled Courses" active>
                    <EnrolledCourses :courseItems="enrolledData" />
                </b-tab>
                <b-tab title="Expired Courses">
                    <ExpiredCourses :courseItems="expiredData" />
                </b-tab>
                <b-tab title="Course Completed">
                    <CourseCompleted :courseItems="completedDdata" />
                </b-tab>
                <b-tab title="Assets">
                    <AssetsDownload />
                </b-tab>
                <b-tab title="Payment History">
                    <PaymentHistory />
                </b-tab>
                <b-tab title="User Profile">
                    <ProfileInformation />
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import api from '@/services/api'
import Helper from "@/services/helper"
import store from "@/store"

import ProfileInformation from '../partials/ProfileInformation.vue'
import EnrolledCourses from '../partials/EnrolledCourses.vue'
import ExpiredCourses from '../partials/ExpiredCourses.vue'
import CourseCompleted from '../partials/CourseCompleted.vue'
import PaymentHistory from '../partials/PaymentHistory.vue'
import AssetsDownload from '../partials/AssetsDownload.vue'

export default{
    name: 'UserTab',
    components: {
        ProfileInformation,
        EnrolledCourses,
        ExpiredCourses,
        CourseCompleted,
        PaymentHistory,
        AssetsDownload,
    },
    data() {
        return {
            itemSource:'private',
            enrolledData:[],
            expiredData:[],
            completedDdata:[],

            filterData:{
                user_id:store.getters.user.id,
                item_type:"course",
                sort:"latest",
            },
        }
    },
    mounted(){
        let app = this;
        app.renderItems();
    },
    methods:{
        renderItems(){
            let app = this;
            Helper.showSpinner();
            axios.post(api.getUrl('/user/courses'), app.filterData).then(function (response) {
                if(response.data.success){
                    app.pageData = false;
                    response.data.data.map(function(value) {
                        app.enrolledData.push(value);
                        if(value.expiration_status){
                            app.expiredData.push(value);
                        }
                        if(value.learning_status >= 100){
                            app.completedDdata.push(value);
                        }
                    });
                    app.$root.$emit("enrolled-count", app.enrolledData.length);
                    app.$root.$emit("expired-count", app.expiredData.length);
                    app.$root.$emit("completed-count", app.completedDdata.length);
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
    },
}
</script>